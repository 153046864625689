//
// Code Customization
//

code {
	background-color: kt-base-color(grey, 1);
	padding: 0.15rem 0.25rem;

	@include kt-rounded {
		border-radius: $kt-border-radius;
	}
}
