//
// Checkbox
//

// Config
$kt-radio-base-color: kt-base-color(grey, 1);

$kt-radio-config: (
	self: (
		transition: all 0.3s ease,
		size: 18px,
		tick: 6px
	),
	outline: (
		border: (
			default: darken($kt-radio-base-color, 12%),
			checked: darken($kt-radio-base-color, 15%)
		),
		tick: darken($kt-radio-base-color, 18%)
	),
	solid: (
		bg: (
			default: darken($kt-radio-base-color, 6%),
			checked: darken($kt-radio-base-color, 8%)
		),
		tick: darken($kt-radio-base-color, 30%)
	)
);

// Base
.kt-radio {
	display: inline-block;
	position: relative;
	padding-left: 30px;
	text-align: left;
	margin-bottom: 10px;
	cursor: pointer;
	font-size: 1rem;
	transition: kt-get($kt-radio-config, self, transition);

	&.kt-radio--disabled {
		opacity: 0.8;
		cursor: not-allowed;
	}

	// Hide default browser input
	> input {
		position: absolute;
		z-index: -1;
		opacity: 0;
	}

	> span {
		background: none;
		position: absolute;
		top: 1px;
		left: 0;
		height: kt-get($kt-radio-config, self, size);
		width: kt-get($kt-radio-config, self, size);
		border-radius: 50% !important; // Makes radio buttons circlular

		&:after {
			content: '';
			position: absolute;
			display: none; // Hide check
			top: 50%;
			left: 50%;
			margin-left: -(kt-get($kt-radio-config, self, tick) / 2);
			margin-top: -(kt-get($kt-radio-config, self, tick) / 2);
			width: kt-get($kt-radio-config, self, tick);
			height: kt-get($kt-radio-config, self, tick);
			border-radius: 100% !important;
		}
	}

	> input:checked ~ span {
		transition: kt-get($kt-radio-config, self, transition);
		background: none;
		&:after {
			display: block;
		}
	}

	&:hover > input:not([disabled]):checked ~ span,
	> input:checked ~ span {
		transition: kt-get($kt-radio-config, self, transition);
	}

	> input:disabled ~ span {
		opacity: 0.6;
		pointer-events: none;
	}

	&.kt-radio--solid {
		> span {
			border: 1px solid transparent;
		}

		&:hover > input:not([disabled]) ~ span,
		> input:focus ~ span {
			transition: kt-get($kt-radio-config, self, transition);
		}
	}

	&.kt-radio--square {
		> span {
			border-radius: 0;
		}
	}

	&.kt-radio--bold > span {
		border-width: 2px !important;
		transition: kt-get($kt-radio-config, self, transition);
	}

	.form-inline & {
		margin-left: 15px;
		margin-right: 15px;
	}

	&.kt-radio--single {
		width: kt-get($kt-radio-config, self, size);
		height: kt-get($kt-radio-config, self, size);

		> span {
			top: 0px;
		}

		th > &,
		td > & {
			right: -5px;
		}
	}

	// Input group
	.input-group & {
		margin-bottom: 0 !important;
		padding-left: 0;
	}
}

.kt-radio-list {
	padding: 0 0;

	.form-horizontal .form-group & {
		padding-top: 0;
	}

	.kt-radio {
		display: block;
		text-align: left;

		&:last-child {
			margin-bottom: 5px;
		}
	}
}

.kt-radio-inline {
	padding: 0 0;

	.kt-radio {
		display: inline-block;
		margin-right: 15px;
		margin-bottom: 5px;

		&:last-child {
			margin-right: 0;
		}
	}
}

.form-group.row {
	.kt-radio-inline {
		margin-top: 0.75rem;
	}

	.kt-radio-list {
		margin-top: 2px;
	}
}

// Skin
@mixin kt-radio-theme(
	$outline-border-color,
	$outline-border-checked-color,
	$outline-tick-color,
	$solid-bg-color,
	$solid-bg-checked-color,
	$solid-tick-color
) {
	&.kt-radio--disabled {
		opacity: 0.8;
	}

	// Outline
	> span {
		border: 1px solid $outline-border-color;

		&:after {
			border: solid $outline-tick-color;
			background: $outline-tick-color;
		}
	}

	> input:disabled ~ span {
		&:after {
			border-color: $outline-border-checked-color;
		}
	}

	> input:checked ~ span {
		border: 1px solid $outline-border-checked-color;
	}

	&.kt-radio--bold > input:checked ~ span {
		border: 2px solid $outline-border-checked-color;
	}

	> input:disabled ~ span {
		opacity: 0.6;
	}

	// Solid
	&.kt-radio--solid {
		> span {
			background: $solid-bg-color;
			border: 1px solid transparent !important;

			&:after {
				border: solid $solid-tick-color;
				background: $solid-tick-color;
			}
		}

		> input:focus ~ span {
			border: 1px solid transparent !important;
		}

		> input:checked ~ span {
			background: $solid-bg-checked-color;
		}
	}

	// Tick
	&.kt-radio--tick {
		> span {
			background: kt-get($kt-checkbox-config, solid, bg, default);
			border: 1px solid transparent !important;
		}

		> input:focus ~ span {
			border: 1px solid transparent !important;
		}

		> input:checked ~ span {
			background: kt-get($kt-checkbox-config, solid, bg, default);

			&:after {
				border: solid $solid-bg-checked-color;
				background: $solid-bg-checked-color;
			}
		}
	}
}

// Default
.kt-radio {
	@include kt-radio-theme(
		kt-get($kt-radio-config, outline, border, default),
		kt-get($kt-radio-config, outline, border, checked),
		kt-get($kt-radio-config, outline, tick),
		kt-get($kt-radio-config, solid, bg, default),
		kt-get($kt-radio-config, solid, bg, checked),
		kt-get($kt-radio-config, solid, tick)
	);

	// State Colors
	@each $name, $color in $kt-state-colors {
		// default state
		&.kt-radio--#{$name} {
			@include kt-radio-theme(
				kt-get($color, base),
				kt-get($color, base),
				kt-get($color, base),
				kt-get($color, base),
				kt-get($color, base),
				kt-get($color, inverse)
			);
		}
	}
}
