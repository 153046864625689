//
// User Card v2
//

.kt-user-card-v2 {
	display: flex;
	align-items: center;
	padding: 0;
	margin: 0;

	.kt-user-card-v2__pic {
		.kt-badge {
			width: 40px;
			height: 40px;
		}

		img {
			border-radius: 50%;
			max-width: 40px;
			max-height: 40px;
		}

		padding-right: 0.5rem;
	}

	.kt-user-card-v2__details {
		line-height: 0;

		.kt-user-card-v2__name {
			display: block;
			font-size: 1rem;
			font-weight: 500;
			line-height: 1.5rem;
			transition: color 0.3s ease;
			color: kt-base-color(label, 3);
		}

		a.kt-user-card-v2__name:hover {
			transition: color 0.3s ease;
			color: kt-brand-color();
		}

		.kt-user-card-v2__desc,
		.kt-user-card-v2__email {
			margin-top: 3px;
			display: inline-block;
			font-size: 0.9rem;
			line-height: 1rem;
			font-weight: 400;
			color: kt-base-color(label, 2);
		}

		a.kt-user-card-v2__email:hover {
			transition: color 0.3s ease;
			color: kt-brand-color();
		}
	}

	&.kt-user-card-v2--uncircle {
		.kt-user-card-v2__pic {
			img {
				border-radius: $kt-border-radius;
			}
		}
	}
}

@include kt-tablet-and-mobile {
	.kt-user-card-v2 {
		.kt-user-card-v2__pic {
			img {
				max-width: 30px;
			}
		}
	}
}
