// Alert
.kt-mat-alert {
	display: flex;
	//flex-wrap: wrap;
	align-items: center;
	padding: 10px 10px 10px 20px;
	border-radius: 3px;
	margin: 0 0 20px 0;

	.kt-mat-alert__icon {
		display: flex;
		align-items: center;
		padding: 0 15px 0 0;

		i {
			font-size: 2.4rem;
			color: #fff;
		}
	}

	.kt-mat-alert__text {
		display: flex;
		align-items: center;
		flex-grow: 1;
		color: #fff;
	}

	.kt-mat-alert__close {
		padding: 0 0 0 10px;
		display: flex;
		align-items: center;
		ustify-content: flex-end;

		.material-icons {
			opacity: 0.8;
			transition: all 0.3s ease;
			color: #fff;

			&:hover {
				transition: all 0.3s ease;
				opacity: 1;
			}
		}
	}

	// States
	&.kt-mat-alert--warn {
		background: kt-get($mat-warn, 500);
	}

	&.kt-mat-alert--primary {
		background: kt-get($mat-primary, 500);
	}

	&.kt-mat-alert--accent {
		background: kt-get($mat-accent, 500);
	}

	// Marginless
	&.kt-mat-alert--marginless {
		margin: 0;
	}
}
