//
// Widget28
//

.kt-widget28 {
	.kt-widget28__visual {
		position: relative;
		min-height: 286px;
		background-repeat: no-repeat;
		background-size: cover;

		@include kt-rounded {
			border-top-left-radius: $kt-border-radius;
			border-top-right-radius: $kt-border-radius;
		}
	}

	.kt-widget28__wrapper {
		width: 100%;

		.nav {
			display: table;
			width: 100%;
			table-layout: fixed;
			border-spacing: 1.2rem;
			margin-top: -8rem;
			position: absolute;
			left: 50%;
			transform: translate(-50%, -50%);
			padding: 0 1rem;

			.nav-item {
				display: table-cell;

				> a {
					text-align: center;
					padding: 1rem 0 1rem 0;
					border: 1px solid rgba(#fff, 0);

					> span {
						display: block;
						color: rgba(#fff, 0.7);
						transition: color 0.3s ease;

						> i {
							font-size: 2.2rem;
							margin-right: 0;
							padding-bottom: 1rem;
							padding-top: 0.4rem;
						}

						&:last-child {
							font-weight: 400;
							font-size: 1rem;
						}
					}

					&.active {
						border: 1px solid rgba(#fff, 1);
						background-color: transparent;

						> span {
							color: #fff;
							transition: color 0.3s ease;

							> i {
								color: #fff;
								transition: color 0.3s ease;
							}
						}
					}

					&:focus,
					&:active,
					&:hover {
						background-color: transparent;

						> span {
							color: #fff;
							transition: color 0.3s ease;

							> i {
								color: #fff;
								transition: color 0.3s ease;
							}
						}
					}
				}
			}
		}

		.tab-content {
			margin-top: 2rem;

			.tab-pane {
				.kt-widget28__tab-items {
					.kt-widget28__tab-item {
						margin-top: 1.2rem;

						> span {
							display: block;

							&:first-child {
								font-size: 1rem;
								font-weight: 400;
								color: kt-base-color(label, 2);
							}

							&:last-child {
								color: kt-base-color(label, 3);
								font-size: 1.1rem;
								font-weight: 500;
								margin-bottom: 1rem;
							}
						}

						&:first-child {
							margin-top: 0;
						}

						border-bottom: 1px solid kt-base-color(grey, 2);

						&:last-child {
							border-bottom: none;
						}
					}
				}
			}
		}
	}
}
