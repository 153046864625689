//
// Checkbox
//

// Config
$kt-checkbox-base-color: kt-base-color(grey, 1);

$kt-checkbox-config: (
	self: (
		transition: all 0.3s ease,
		size: 18px,
		tick: 6px
	),
	outline: (
		border: (
			default: darken($kt-checkbox-base-color, 12%),
			checked: darken($kt-checkbox-base-color, 15%)
		),
		tick: darken($kt-checkbox-base-color, 18%)
	),
	solid: (
		bg: (
			default: darken($kt-checkbox-base-color, 6%),
			checked: darken($kt-checkbox-base-color, 8%)
		),
		tick: darken($kt-checkbox-base-color, 30%)
	)
);

// Base
.kt-checkbox {
	display: inline-block;
	position: relative;
	padding-left: 30px;
	margin-bottom: 10px;
	text-align: left;
	cursor: pointer;
	font-size: 1rem;
	transition: kt-get($kt-checkbox-config, self, transition);

	&.kt-checkbox--disabled {
		opacity: 0.8;
		cursor: not-allowed;
	}

	// Hide default browser input
	> input {
		position: absolute;
		z-index: -1;
		opacity: 0;
	}

	> span {
		@include kt-rounded {
			border-radius: 3px;
		}

		background: none;
		position: absolute;
		top: 1px;
		left: 0;
		height: kt-get($kt-checkbox-config, self, size);
		width: kt-get($kt-checkbox-config, self, size);

		&:after {
			content: '';
			position: absolute;
			display: none; // Hide check
			top: 50%;
			left: 50%;
			margin-left: -2px;
			margin-top: -6px;
			width: 5px;
			height: 10px;
			border-width: 0 2px 2px 0#{'/*rtl:ignore*/'} !important;
			transform: rotate(45deg) #{'/*rtl:ignore*/'};
		}
	}

	> input:checked ~ span {
		transition: kt-get($kt-checkbox-config, self, transition);
		background: none;
		&:after {
			display: block;
		}
	}

	&:hover > input:not([disabled]):checked ~ span,
	> input:checked ~ span {
		transition: kt-get($kt-checkbox-config, self, transition);
	}

	> input:disabled ~ span {
		opacity: 0.6;
		pointer-events: none;
	}

	&.kt-checkbox--solid {
		> span {
			border: 1px solid transparent;
		}

		&:hover > input:not([disabled]) ~ span,
		> input:focus ~ span {
			transition: kt-get($kt-checkbox-config, self, transition);
		}
	}

	&.kt-checkbox--square {
		> span {
			border-radius: 0;
		}
	}

	&.kt-checkbox--bold > span {
		border-width: 2px !important;
		transition: kt-get($kt-checkbox-config, self, transition);
	}

	.form-inline & {
		margin-left: 15px;
		margin-right: 15px;
	}

	&.kt-checkbox--single {
		width: kt-get($kt-checkbox-config, self, size);
		height: kt-get($kt-checkbox-config, self, size);

		> span {
			top: 0px;
		}

		th > &,
		td > & {
			right: -5px;
		}
	}

	// Input group
	.input-group & {
		margin-bottom: 0 !important;
		padding-left: 0;
	}
}

.kt-checkbox-list {
	padding: 0 0;

	.form-horizontal .form-group & {
		padding-top: 0;
	}

	.kt-checkbox {
		text-align: left;
		display: block;

		&:last-child {
			margin-bottom: 5px;
		}
	}
}

.kt-checkbox-inline {
	padding: 0 0;

	.kt-checkbox {
		display: inline-block;
		margin-right: 15px;
		margin-bottom: 5px;

		&:last-child {
			margin-right: 0;
		}
	}
}

.form-group.row {
	.kt-checkbox-inline {
		margin-top: 0.75rem;
	}

	.kt-checkbox-list {
		margin-top: 2px;
	}
}

// Skin
@mixin kt-checkbox-theme(
	$outline-border-color,
	$outline-border-checked-color,
	$outline-tick-color,
	$solid-bg-color,
	$solid-bg-checked-color,
	$solid-tick-color
) {
	&.kt-checkbox--disabled {
		opacity: 0.7;
	}

	// Outline
	> span {
		border: 1px solid $outline-border-color;

		&:after {
			border: solid $outline-tick-color;
		}
	}

	> input:disabled ~ span {
		&:after {
			border-color: $outline-border-checked-color;
		}
	}

	> input:checked ~ span {
		border: 1px solid $outline-border-checked-color;
	}

	&.kt-checkbox--bold > input:checked ~ span {
		border: 2px solid $outline-border-checked-color;
	}

	> input:disabled ~ span {
		opacity: 0.6;
	}

	// Solid
	&.kt-checkbox--solid {
		> span {
			background: $solid-bg-color;
			border: 1px solid transparent !important;

			&:after {
				border: solid $solid-tick-color;
			}
		}

		> input:focus ~ span {
			border: 1px solid transparent !important;
		}

		> input:checked ~ span {
			background: $solid-bg-checked-color;
		}
	}

	// Tick
	&.kt-checkbox--tick {
		> span {
			background: kt-get($kt-checkbox-config, solid, bg, default);
			border: 1px solid transparent !important;

			&:after {
				border: solid $solid-tick-color;
			}
		}

		> input:focus ~ span {
			border: 1px solid transparent !important;
		}

		> input:checked ~ span {
			border: 1px solid transparent !important;
			background: kt-get($kt-checkbox-config, solid, bg, default);

			&:after {
				border: solid $solid-bg-checked-color;
			}
		}
	}
}

// Default
.kt-checkbox {
	// Base
	@include kt-checkbox-theme(
		kt-get($kt-checkbox-config, outline, border, default),
		kt-get($kt-checkbox-config, outline, border, checked),
		kt-get($kt-checkbox-config, outline, tick),
		kt-get($kt-checkbox-config, solid, bg, default),
		kt-get($kt-checkbox-config, solid, bg, checked),
		kt-get($kt-checkbox-config, solid, tick)
	);

	// State Colors
	@each $name, $color in $kt-state-colors {
		// default state
		&.kt-checkbox--#{$name} {
			@include kt-checkbox-theme(
				kt-get($color, base),
				kt-get($color, base),
				kt-get($color, base),
				kt-get($color, base),
				kt-get($color, base),
				kt-get($color, inverse)
			);
		}
	}
}
