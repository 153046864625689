//
// Widget33
//

// Base
.kt-widget33 {
	// Head
	.kt-widget33__head {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 3rem;

		.kt-widget33__title {
			font-size: 1.4rem;
			font-weight: 600;
			color: kt-base-color(label, 3);

			&:hover {
				color: kt-brand-color();
				transition: all 0.3s ease;
			}
		}
	}

	// Bady
	.kt-widget33__body {
		.kt-widget33__title {
			font-size: 1.4rem;
			font-weight: 600;
			color: kt-base-color(label, 3);

			&:hover {
				color: kt-brand-color();
				transition: all 0.3s ease;
			}
		}

		.kt-widget33__desc {
			display: block;
			font-weight: 500;
			color: kt-base-color(label, 2);
		}

		.kt-widget33__items {
			.kt-widget33__item {
				display: flex;
				align-items: center;
				padding: 2rem 0;

				.kt-widget33__pic {
					width: 8rem;
					margin-right: 2rem;

					@include kt-rounded {
						border-radius: $kt-border-radius;
					}
				}

				.kt-widget33__content {
					flex-grow: 1;
					margin-top: -0.4rem;

					.kt-widget33__subtitle {
						font-weight: 500;
						color: kt-base-color(label, 2);
						margin-bottom: 0.5rem;
						display: block;
					}

					.kt-widget33__action {
						display: flex;
						align-items: center;

						.kt-widget33__check {
							@include kt-rounded {
								border-radius: $kt-border-radius;
							}
						}

						.btn {
							width: 23px;
							height: 23px;
							padding: 0;
							margin: 0;

							i {
								padding: 0;
								font-size: 0.8rem;
							}
						}

						span {
							font-size: 1.2rem;
							font-weight: 600;
							color: kt-base-color(label, 3);
							padding-left: 1rem;
						}
					}
				}

				.kt-widget33__price {
					display: flex;
					flex-wrap: wrap;
					font-size: 1.1rem;
					font-weight: 600;
					color: kt-base-color(label, 3);
					margin-top: -2.7rem;
				}

				border-bottom: 1px solid kt-base-color(grey, 2);

				&:last-child {
					border-bottom: none;
				}
			}
		}
	}

	// Foot
	.kt-widget33__foot {
		.kt-widget33__section {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 2rem;

			.kt-widget33__desc {
				font-weight: 600;
				font-size: 1.2rem;
				color: kt-base-color(label, 4);
			}

			.kt-widget33__subtotal {
				font-weight: 600;
				color: kt-base-color(label, 4);
				font-size: 1.2rem;
			}
		}

		.kt-widget33__button {
			text-align: center;

			.btn {
				width: 100%;
				padding: 1rem 0;
			}
		}
	}
}
