//
// User Profile 3
//

.kt-widget {
	&.kt-widget--user-profile-3 {
		.kt-widget__top {
			display: flex;
			align-items: flex-start;

			.kt-widget__media {
				margin-top: 0.2rem;

				img {
					width: 110px;

					@include kt-rounded {
						border-radius: 8px;
					}
				}
			}

			.kt-widget__pic {
				margin-top: 0.2rem;
				width: 100%;
				max-width: 110px;
				height: 110px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 1.5rem;

				@include kt-rounded {
					border-radius: 8px;
				}

				@each $name, $color in $kt-state-colors {
					&.kt-widget__pic--#{$name} {
						background: rgba(kt-get($color, base), 0.1);
					}
				}
			}

			.kt-widget__content {
				width: 100%;
				padding-left: 1.7rem;

				.kt-widget__head {
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: wrap;

					.kt-widget__user {
						display: flex;
						align-items: center;
					}

					.kt-widget__username {
						font-size: 1.3rem;
						color: kt-base-color(label, 4);
						font-weight: 500;
						align-items: center;
						margin-right: 0.5rem;

						&:hover {
							color: kt-brand-color();
							transition: color 0.3s ease;
						}

						i {
							font-size: 1.2rem;
							color: #57c974;
							padding-left: 0.5rem;
						}
					}

					.kt-widget__title {
						font-size: 1.3rem;
						color: kt-base-color(label, 4);
						font-weight: 600;
						margin: 0.8rem 0 0.7rem 0;

						&:hover {
							color: kt-brand-color();
							transition: color 0.3s ease;
						}
					}

					.kt-widget__action {
						.btn {
							font-weight: 600;
							margin-left: 0.5rem;
						}
					}
				}

				.kt-widget__subhead {
					padding: 0.6rem 0 0.8rem 0;

					a {
						padding-right: 2rem;
						color: kt-base-color(label, 2);
						font-weight: 500;

						&:hover {
							color: kt-brand-color();
							transition: color 0.3s ease;
						}

						i {
							padding-right: 0.5rem;
						}
					}
				}

				.kt-widget__info {
					display: flex;
					flex-flow: row wrap;

					.kt-widget__desc {
						color: kt-base-color(label, 3);
						font-weight: 400;
						padding-right: 2rem;
						flex-grow: 1;
						margin-bottom: 0.5rem;
					}

					.kt-widget__progress {
						display: flex;
						align-items: center;
						max-width: 500px;
						width: 100%;
						margin: 0.1rem 0;

						.kt-widget__text {
							padding-right: 1rem;
							color: kt-base-color(label, 3);
							font-weight: 500;
						}

						.kt-widget__stats {
							padding-left: 1rem;
							color: kt-base-color(label, 4);
							font-weight: 600;
							font-size: 1.1rem;
						}
					}

					.kt-widget__stats {
						margin-bottom: 0.7rem;
						display: flex;
						flex-wrap: wrap;

						.kt-widget__item {
							padding-top: 1rem;

							.kt-widget__date {
								color: kt-base-color(label, 3);
								font-weight: 500;
								padding-bottom: 1rem;
								display: block;
							}

							.kt-widget__subtitel {
								color: kt-base-color(label, 3);
								font-weight: 500;
								display: block;
							}

							.kt-widget__progress {
								width: 100%;
								margin: 1.4rem 0 0.5rem 0;

								.kt-widget__stat {
									padding-left: 0.7rem;
									color: kt-base-color(label, 4);
									font-weight: 600;
								}
							}

							&:not(:first-child):not(:last-child) {
								margin: 0 2.2rem;
							}

							&:last-child {
								padding-left: 1rem;
							}
						}
					}
				}
			}
		}

		.kt-widget__bottom {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			width: 100%;
			border-top: 1px solid kt-base-color(grey, 2);
			margin-top: 2rem;

			.kt-widget__item {
				display: flex;
				align-items: center;
				flex-grow: 1;
				padding: 2rem 1.5rem 0 0;

				.kt-widget__icon {
					i {
						font-size: 2.7rem;
						color: kt-base-color(label, 1);
						font-weight: 400;
					}
				}

				.kt-widget__details {
					padding-left: 1rem;

					.kt-widget__title {
						display: block;
						color: kt-base-color(label, 3);
						font-weight: 600;
						font-size: 0.95rem;
					}

					.kt-widget__value {
						display: block;
						color: kt-base-color(label, 4);
						font-weight: 600;
						font-size: 1.2rem;

						span {
							color: kt-base-color(label, 3);
							font-weight: 400;
						}
					}

					a.kt-widget__value {
						font-size: 0.95rem;

						&:hover {
							color: kt-brand-color();
						}
					}

					.kt-section__content {
						padding-left: 0.7rem;
					}
				}
			}
		}

		.kt-widget__form {
			margin-top: 1.5rem;
		}
	}
}

@include kt-mobile {
	.kt-widget {
		&.kt-widget--user-profile-3 {
			padding-top: 0.5rem;

			.kt-widget__top {
				display: flex;
				align-items: flex-start;
				.kt-widget__media {
					margin-top: 0.5rem;

					img {
						max-width: 60px;
					}
				}

				.kt-widget__pic {
					width: 60px;
					height: 60px;
					margin-top: 0.5rem;
					font-size: 1.3rem;
				}

				.kt-widget__content {
					padding-left: 1rem;
					margin-top: 0;

					.kt-widget__head {
						.kt-widget__username {
							padding-bottom: 0.6rem;
						}
					}

					.kt-widget__subhead {
						padding: 1.2rem 0;

						a {
							&:not(:first-child):not(:last-child) {
								padding: 0.5rem 1rem 0.5rem 0;
							}
						}
					}

					.kt-widget__info {
						display: flex;
						flex-direction: column;

						.kt-widget__desc {
							padding-bottom: 1rem;
						}

						.kt-widget__progress {
							width: 100%;
						}
					}
				}
			}

			.kt-widget__bottom {
				padding-top: 1rem;

				.kt-widget__item {
					padding: 1rem 1rem 0 0;

					.kt-widget__icon {
						i {
							font-size: 2.5rem;
						}
					}
				}
			}
		}
	}
}
