/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~material-design-icons/iconfont/material-icons.css';
@import '~highlight.js/styles/googlecode.css';

// @import "~ag-grid-enterprise/dist/styles/ag-grid.min.css";
// @import "~ag-grid-enterprise/dist/styles/ag-theme-material.min.css";

@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-balham.css';
@import '~ag-grid-community/dist/styles/ag-theme-balham-dark.css';
@import '~ag-grid-community/dist/styles/ag-theme-blue.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');

.ag-theme-balham .ag-header {
	background-color: #f5f7f7;
	color: rgba(0, 0, 0, 0.54);
	font-weight: 600;
	font-size: 15px;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue',
		sans-serif;
	border-bottom: 1px solid #bdc3c7;
}

.ag-theme-balham {
	background-color: white;
	color: #000;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue',
		sans-serif;
	font-weight: 400;
	font-size: 15px;
}

// @import "../node_modules/ag-grid-community/src/styles/ag-grid.scss";
// @import "../node_modules/ag-grid-community/src/styles/ag-theme-blue/sass/ag-theme-blue.scss";

/* For Global Loading Screen */
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

// header base skins
.kt-header-base-light {
	@import 'sass/global/layout/header/skins/base/light.scss';
}

.kt-header-base-dark {
	@import 'sass/global/layout/header/skins/base/dark.scss';
}

// header menu skins
.kt-header-menu-light {
	@import 'sass/global/layout/header/skins/menu/light.scss';
}

.kt-header-menu-dark {
	@import 'sass/global/layout/header/skins/menu/dark.scss';
}

// brand skins
.kt-brand-dark {
	@import 'sass/global/layout/brand/skins/dark.scss';
}

.kt-brand-light {
	@import 'sass/global/layout/brand/skins/light.scss';
}

// aside skins
.kt-aside-dark {
	@import 'sass/global/layout/aside/skins/dark.scss';
}

.kt-aside-light {
	@import 'sass/global/layout/aside/skins/light.scss';
}
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~open-iconic/font/css/open-iconic-bootstrap.css';

.kt-header-mobile {
	z-index: 1005 !important;
}

.kt-aside {
	z-index: 1006 !important;
}

.kt-aside-close {
	z-index: 1007 !important;
}

.cdk-overlay-container {
	z-index: 1008 !important;
}

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
	display: none !important;
}

.mat-table__wrapper--dashboard .mat-table {
	min-width: 600px !important;
	width: 100% !important;
}

.position-static {
	position: static;
}

.holder {
	animation-name: example;
	animation-duration: 0.5s;
}

@keyframes example {
	0% {
		transform: scale(0.1);
	}

	100% {
		transform: scale(1);
	}
}

.panel {
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0px 10px 20px 0px #7979798c;
}

::ng-deep .fc-content {
	font-size: 14px;
}

::ng-deep .fc-event {
	cursor: pointer;
}

// ::ng-deep.toast-panel>.toast-card>.ng-star-inserted
// {
//     font-size: 50px !important;
// }

.content-body {
	font-size: 15px !important;
}

.mat-cell,
.mat-header-cell,
.mat-footer-cell {
	padding: 0px 10px !important;

	&:not(:last-child) {
		border-right: 1px solid#d3d3d3;
	}
}

.mat-footer-cell {
	font-size: 12px !important;
	font-weight: 500;
	color: rgba(0, 0, 0, 0.54) !important;
}

.kt-login__form {
	background-color: rgba(255, 255, 255, 0.95);
	border-radius: 7px;
}

.mat-input-element:disabled {
	color: rgba(0, 0, 0, 0.85) !important;
}
