//
// Fontawesome5
//

.fa-lg {
	font-size: 1.33333em !important;
}

.fa-xs {
	font-size: 0.75em !important;
}

.fa-sm {
	font-size: 0.875em !important;
}

.fa-1x {
	font-size: 1em !important;
}

.fa-2x {
	font-size: 2em !important;
}

.fa-3x {
	font-size: 3em !important;
}

.fa-4x {
	font-size: 4em !important;
}

.fa-5x {
	font-size: 5em !important;
}

.fa-6x {
	font-size: 6em !important;
}

.fa-7x {
	font-size: 7em !important;
}

.fa-8x {
	font-size: 8em !important;
}

.fa-9x {
	font-size: 9em !important;
}

.fa-10x {
	font-size: 10em !important;
}
