//
// Widget 17
//

.kt-widget17 {
	.kt-widget17__visual {
		@include kt-rounded {
			border-top-left-radius: $kt-border-radius;
			border-top-right-radius: $kt-border-radius;
		}

		.kt-widget17__chart {
			position: relative;
			padding-top: 8rem;
		}
	}

	.kt-widget17__stats {
		display: column;
		margin: -4.3rem auto 0 auto;
		position: relative;
		width: 90%;

		// Items
		.kt-widget17__items {
			display: flex;

			.kt-widget17__item {
				padding: 2rem;
				flex: 1;
				overflow: hidden;
				background-color: white;
				margin: 0.3rem;
				box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.06);
				cursor: pointer;
				transition: all 0.3s ease;

				.kt-widget17__icon {
					display: block;

					> i {
						font-size: 2.6rem;
					}

					svg {
						margin-left: -4px;
						width: 38px;
						height: 38px;
					}
				}

				.kt-widget17__subtitle {
					display: block;
					margin-top: 0.75rem;
					font-size: 1.2rem;
					font-weight: 500;
					color: kt-base-color(label, 3);
				}

				.kt-widget17__desc {
					display: block;
					font-size: 1rem;
					color: kt-base-color(label, 2);
				}

				&:hover {
					transition: all 0.3s ease;
					box-shadow: 0px 1px 21px 1px rgba(69, 65, 78, 0.12);
				}
			}
		}
	}
}

@include kt-mobile {
	.kt-widget17 {
		.kt-widget17__stats {
			.kt-widget17__items {
				.kt-widget17__item {
					padding-left: 0.5rem;
				}
			}
		}
	}
}
