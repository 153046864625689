//
// User Profile 2
//

.kt-widget {
	&.kt-widget--user-profile-2 {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		height: 100%;

		.kt-widget__head {
			display: flex;
			align-items: center;
			margin-top: -45px;

			.kt-widget__media {
				.kt-widget__img {
					max-width: 90px;

					@include kt-rounded {
						border-radius: 50%;
					}
				}

				.kt-widget__pic {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 90px;
					height: 90px;
					font-size: 1.5rem;

					@include kt-rounded {
						border-radius: 50%;
					}

					@each $name, $color in $kt-state-colors {
						&.kt-widget__pic--#{$name} {
							background: rgba(kt-get($color, base), 0.1);
						}
					}
				}
			}

			.kt-widget__info {
				padding-left: 1rem;

				.kt-widget__username {
					font-size: 1.4rem;
					color: kt-base-color(label, 4);
					font-weight: 500;

					&:hover {
						color: kt-brand-color();
						transition: color 0.3s ease;
					}
				}

				.kt-widget__titel {
					font-size: 1.4rem;
					color: kt-base-color(label, 4);
					font-weight: 500;

					&:hover {
						color: kt-brand-color();
						transition: color 0.3s ease;
					}
				}

				.kt-widget__desc {
					display: block;
					font-weight: 500;
					font-size: 1.1rem;
					padding-top: 0.4rem;
					color: kt-base-color(label, 2);
				}
			}
		}

		.kt-widget__body {
			flex: 1;

			.kt-widget__section {
				padding: 1rem 0 1rem 0;
				color: kt-base-color(label, 3);
				font-weight: 400;

				a {
					padding-right: 0.3rem;
				}
			}

			.kt-widget__content {
				display: flex;

				.kt-widget__stats {
					display: flex;
					align-items: center;
					flex-grow: 1;
					padding-bottom: 1.7rem;

					.kt-widget__icon {
						i {
							font-size: 2.7rem;
							color: kt-base-color(label, 1);
							font-weight: 400;
						}
					}

					.kt-widget__details {
						padding-left: 1rem;

						.kt-widget__title {
							display: block;
							color: kt-base-color(label, 3);
							font-weight: 500;
							font-size: 0.95rem;
						}

						.kt-widget__value {
							display: block;
							color: kt-base-color(label, 4);
							font-weight: 600;
							font-size: 1.2rem;
						}
					}
				}
			}

			.kt-widget__item {
				padding: 0.7rem 0;

				.kt-widget__contact {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-bottom: 0.5rem;

					.kt-widget__label {
						color: kt-base-color(label, 4);
						font-weight: 600;
					}

					.kt-widget__data {
						color: kt-base-color(label, 2);
						font-weight: 400;
					}

					a.kt-widget__data:hover {
						color: kt-brand-color();
						transition: color 0.3s ease;
					}

					&:last-child {
						padding-bottom: 0;
					}
				}
			}
		}

		.kt-widget__footer {
			margin-top: 2rem;

			.btn {
				font-size: 1rem;
				font-weight: 600;
				padding: 1.1rem 0;
				width: 100%;
			}
		}
	}
}

@include kt-mobile {
	.kt-widget {
		&.kt-widget--user-profile-2 {
			.kt-widget__head {
				margin-top: -30px;

				.kt-widget__media {
					.kt-widget__img {
						max-width: 60px;
					}

					.kt-widget__pic {
						max-width: 60px;
						max-height: 60px;
						font-size: 1.2rem;
					}
				}
			}
		}
	}
}
