//
// Alert
//

.alert {
	display: flex;
	align-items: stretch;
	padding: 1rem 2rem;
	margin: 0 0 $grid-gutter-width 0;

	@include kt-rounded {
		border-radius: $kt-border-radius;
	}

	.alert-icon {
		display: flex;
		align-items: center;
		padding: 0 1.25rem 0 0;

		i {
			font-size: 2.4rem;
		}

		&.alert-icon-top {
			align-items: flex-start;
			align-content: stretch;
		}
	}

	.alert-text {
		align-self: center;
		flex-grow: 1;
	}

	.alert-close {
		padding: 0 0 0 1rem;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.close {
			text-shadow: none !important;
		}

		i {
			opacity: 0.8;
			transition: all 0.3s ease;
			cursor: pointer;

			&:hover {
				transition: all 0.3s ease;
				opacity: 1;
			}
		}
	}

	// Elevate
	&.alert-elevate {
		box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.1);
	}

	// States
	@each $name, $color in $kt-state-colors {
		&.alert-#{$name} {
			background: kt-get($kt-state-colors, $name, base);
			border: 1px solid kt-get($kt-state-colors, $name, base);
			color: kt-get($kt-state-colors, $name, inverse);

			.alert-icon {
				i {
					color: kt-get($kt-state-colors, $name, inverse);
				}
			}

			.alert-text {
				color: kt-get($kt-state-colors, $name, inverse);

				hr {
					border-top-color: kt-get($kt-state-colors, $name, inverse);
					opacity: 0.4;
				}
			}

			.alert-close {
				i {
					color: kt-get($kt-state-colors, $name, inverse);
				}
			}
		}

		&.alert-outline-#{$name} {
			border: 1px solid kt-get($kt-state-colors, $name, base);
			color: kt-get($kt-state-colors, $name, base);

			.alert-icon {
				i {
					color: kt-get($kt-state-colors, $name, base);
				}
			}

			.alert-text {
				color: kt-get($kt-state-colors, $name, base);
			}

			.alert-close {
				i {
					color: kt-get($kt-state-colors, $name, base);
				}
			}
		}

		&.alert-solid-#{$name} {
			background: rgba(kt-get($kt-state-colors, $name, base), 0.1);
			border-color: transparent;
			color: kt-get($kt-state-colors, $name, base);

			.alert-icon {
				i {
					color: kt-get($kt-state-colors, $name, base);
				}
			}

			.alert-text {
				color: kt-get($kt-state-colors, $name, base);
			}

			.alert-close {
				i {
					color: kt-get($kt-state-colors, $name, base);
				}
			}
		}
	}

	// Secondary alert
	&.alert-secondary {
		.alert-icon {
			i {
				color: kt-base-color(label, 2);
			}
		}

		.alert-text {
			color: kt-base-color(label, 3);
		}
	}

	// Marginless
	&.alert--marginless {
		margin: 0;
	}

	&.alert-bold {
		.alert-text {
			font-weight: 500;
		}
	}
}
