//
// Dropzone
//

// Base
.dropzone {
	min-height: auto;
}

.dropzone.dropzone-default {
	padding: 20px;
	text-align: center;
	cursor: pointer;
	border: 2px dashed kt-base-color(grey, 2);

	@include kt-rounded {
		border-radius: $kt-border-radius;
	}

	.dropzone-msg-title {
		color: kt-base-color(label, 3);
		margin: 0 0 5px 0;
		padding: 0;
		font-weight: 500;
		font-size: 1.2rem;
	}

	.dropzone-msg-desc {
		color: kt-base-color(label, 2);
		font-weight: 400;
		font-size: 1rem;
	}

	.dz-preview {
		.dz-image {
			@include kt-rounded {
				border-radius: $kt-border-radius;
			}
		}
	}

	// State colors
	@each $name, $color in $kt-state-colors {
		&.dropzone-#{$name} {
			border-color: kt-get($color, base);
		}
	}
}

// Multipe upload
.dropzone.dropzone-multi {
	border: 0;
	padding: 0;

	.dz-message {
		display: none;
	}

	.dropzone-panel {
		.dropzone-upload,
		.dropzone-remove-all {
			display: none;
		}
	}

	.dropzone-item {
		display: flex;
		align-items: center;
		margin-top: 0.75rem;

		@include kt-rounded {
			border-radius: $kt-border-radius;
			padding: 0.5rem 1rem;
			background-color: darken(#f8f9fc, 1%);
		}

		.dropzone-file {
			flex-grow: 1;

			.dropzone-filename {
				font-size: 0.9rem;
				font-weight: 500;
				color: kt-base-color(label, 3);
				text-overflow: ellipsis;
				margin-right: 0.5rem;

				b {
					font-size: 0.9rem;
					font-weight: 500;
					color: kt-base-color(label, 1);
				}
			}

			.dropzone-error {
				margin-top: 0.25rem;
				font-size: 0.9rem;
				font-weight: 400;
				color: kt-state-color(danger);
				text-overflow: ellipsis;
			}
		}

		.dropzone-progress {
			width: 15%;

			.progress {
				height: 5px;
				@include transition;
			}
		}

		.dropzone-toolbar {
			margin-left: 1rem;
			display: flex;
			flex-wrap: nowrap;

			.dropzone-start,
			.dropzone-cancel,
			.dropzone-delete {
				height: 25px;
				width: 25px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				i {
					font-size: 0.8rem;
					color: kt-base-color(label, 1);
				}

				&:hover {
					i {
						color: kt-brand-color();
					}
				}
			}

			.dropzone-start {
				@include transition;
			}
		}
	}
}
