//
// Iconbox
//

.kt-iconbox {
	padding: 1.5rem;

	.kt-iconbox__body {
		display: flex;
		flex-wrap: nowrap;

		.kt-iconbox__icon {
			$svg-size: 50px;
			flex: 0 0 $svg-size;
			padding-right: 20px;

			svg {
				height: $svg-size;
				width: $svg-size;
			}
		}

		.kt-iconbox__desc {
			display: flex;
			flex-direction: column;

			.kt-iconbox__title {
				font-size: 1.55rem;
				color: kt-base-color(label, 4);

				.kt-link {
					color: kt-base-color(label, 4);
				}
			}

			.kt-iconbox__content {
				font-size: 1.1rem;
				font-weight: 400;
				color: #9392a0;
			}
		}
	}

	a.kt-iconbox__body {
		cursor: pointer;
	}

	&--wave {
		@include wave;

		&:after {
			transform: rotate(45deg);
		}
	}

	&--animate {
		@include wave;

		&:after {
			animation: wave 15s infinite linear;
		}

		&-slower {
			@include wave;

			&:after {
				animation: wave 30s infinite linear;
			}
		}

		&-slow {
			@include wave;

			&:after {
				animation: wave 25s infinite linear;
			}
		}

		&-fast {
			@include wave;

			&:after {
				animation: wave 10s infinite linear;
			}
		}

		&-faster {
			@include wave;

			&:after {
				animation: wave 5s infinite linear;
			}
		}
	}

	@each $name, $color in $kt-state-colors {
		&--#{$name} {
			.kt-iconbox__body {
				.kt-iconbox__icon {
					svg {
						g {
							[fill] {
								fill: kt-get($color, base) !important;
							}
						}
					}
				}
			}

			&.kt-iconbox--animate,
			&.kt-iconbox--animate-slow,
			&.kt-iconbox--animate-slower,
			&.kt-iconbox--animate-fast,
			&.kt-iconbox--animate-faster {
				background-color: rgba(kt-state-color(#{$name}), 0.07) !important;
			}
		}
	}
}

@keyframes wave {
	from {
		transform: rotate(0deg);
	}
	from {
		transform: rotate(360deg);
	}
}
