//
// Widget 20
//

.kt-widget20 {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;

	.kt-widget20__content {
		display: flex;
		padding-top: 1.15rem;
		padding-bottom: 1.25rem;
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		.kt-widget20__desc {
			color: kt-base-color(label, 2);
			font-size: 1rem;
			font-weight: 400;
			margin-top: 0.25rem;
		}

		.kt-widget20__number {
			flex-grow: 1;
			font-weight: 600;
			font-size: 1.8rem;
		}
	}

	.kt-widget20__chart {
		position: relative;
		canvas {
			border-bottom-left-radius: $kt-border-radius;
			border-bottom-right-radius: $kt-border-radius;
		}
	}
}
